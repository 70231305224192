import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorPage from "./pages/errorPage/errorPage";
import Loading from "./pages/loadingPage/loadingPage";
import DataFetch from "./components/dataFetch/dataFetch";
import configData from "./config.json";

function Content() {
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState("");
  const [errorStatusCode, setErrorStatusCode] = useState("");
  const [correlationId, setCorrelationId] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = configData.analyticsUrl;
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <div>
        {loading ? (
          <>
            <Loading />
            <DataFetch
              setIsError={setIsError}
              setErrorStatusCode={setErrorStatusCode}
              setCorrelationId={setCorrelationId}
              setMessage={setMessage}
              setLoading={setLoading}
            />
          </>
        ) : isError ? (
          <ErrorPage errorStatusCode={errorStatusCode} correlationId={correlationId} loading={loading} />
        ) : ""}
      </div>
    </>
  );
}

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Content />} />
          <Route exact path="/check/v1/tag" element={<Content />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
