import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import configData from "../../config.json";

function DataFetch({
  setIsError,
  setErrorStatusCode,
  setCorrelationId,
  setMessage,
  setLoading
}) {

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // return window.location.replace("https://www.rolex.com/");
    const params = new URLSearchParams(window.location.search);
    const ekid = params.get("ekid");
    const enpd = params.get("enpd");
    const enfd = params.get("enfd");
    const cmac = params.get("cmac");
    const rid = params.get("rid");
    const p = params.get("p");
    const c = params.get("c");
    const m = params.get("m");
    const originalUrl = window.location.href;
    const encoder = new TextEncoder();
    const byteArray = encoder.encode(originalUrl);
    const base64Url = btoa(String.fromCharCode.apply(null, byteArray));
    const url1 = `${configData.azureFunctionEndpoint}/Authenticate/v1?ekid=${ekid}&enpd=${enpd}&enfd=${enfd}&cmac=${cmac}&subscription-key=${configData.subscriptionkey}`;
    const url2 = `${configData.azureFunctionEndpoint}/Authenticate/v2?rid=${rid}&p=${p}&c=${c}&m=${m}&subscription-key=${configData.subscriptionkey}&original_url=${base64Url}`;
    const fetchTokenUrl = !ekid ? url2 : url1;

    if (
      (ekid !== null && enpd !== null && enfd !== null && cmac !== null) ||
      (rid !== null &&
        p !== null &&
        c !== null &&
        m !== null &&
        originalUrl !== null)
    ) {
      fetch(fetchTokenUrl)
        .then((response) => {
          if (response.status === 200) {
            window.location.replace("https://www.rolex.com/");

          } else {
            for (const pair of response.headers.entries()) {
              if (pair[0] === "x-correlation-id") {
                setCorrelationId(pair[1]);
              }
            }
            setIsError(true);
            setErrorStatusCode(response.status);
            setLoading(false);
            clearParams(ekid);
            return response.text();
          }
        })
        .then((data) => {
          if (data) {
            setMessage(data);
            setIsError(true);
            setLoading(false);
          }
          clearParams(ekid);
        })
        .catch((e) => {
          setIsError(true);
          setLoading(false);
          console.log(e);
          clearParams(ekid);
        });
    }

    const clearParams = (ekid) => {
      if (ekid) {
        searchParams.delete("ekid");
        searchParams.delete("enpd");
        searchParams.delete("enfd");
        searchParams.delete("cmac");
      } else {
        searchParams.delete("rid");
        searchParams.delete("p");
        searchParams.delete("c");
        searchParams.delete("m");
        searchParams.delete("original_url");
      }
      setSearchParams(searchParams);
    };

    return <></>;
  });
  return <></>;
}

export default DataFetch;
