import React from "react";
import "./demoLabel.scss";

function DemoLabel() {
  const hostName = window.location.hostname;
  function getEnvironment() {
    let env;
    switch (hostName) {
      case "at-cd.rolex.com":
        env = "DEV ENV";
        break;
      case "ci.watchid.rolex.com":
        env = "TEST ENV";
        break;
      case "pp.watchid.rolex.com":
        env = "PPROD";
        break;
      default:
        env = "";
    }
    return env;
  }

  return (
    <>
      {hostName !== "at-cd.rolex.com" || hostName === "localhost" ? (
        ""
      ) : (
        <div className="demo">
          FOR TESTING PURPOSE ONLY ({getEnvironment()})
        </div>
      )}
    </>
  );
}

export default DemoLabel;
